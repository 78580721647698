<template>
	<div>
		<SelectContractLabel />
		<BCard v-if="!processoEmAndamento && !loading && selectedContract && (selectedContract.empreendimento == 39 || selectedContract.empreendimento == 32 || selectedContract.empreendimento == 18)">
			<BCardHeader>
				<BCardTitle class="text-center"> Aprovação do Projeto no Condomínio </BCardTitle>
			</BCardHeader>
			<BCardBody>
				<BCardText>
					<p>
						<b>Instruções para Solicitação de Demarcação de Lote</b>
					</p>
					<p class="text-justify">
						Para realizar a solicitação de demarcação de lote, será necessário anexar a <b>Aprovação do 
							Projeto no Condomínio</b>. Este documento é imprescindível para que possamos dar andamento à sua solicitação.
							
					</p>
					<p class="text-justify">
						<b>Caso você não possua a aprovação do condomínio</b>, referente ao seu projeto, 
							pedimos que se dirija à administração do condomínio e apresente o seu projeto para submetê-lo à aprovação.
					</p>
					<p class="text-justify">
						Lembre-se: sem a aprovação do projeto, não será possível concluir a solicitação de demarcação de lote.
					</p>
					<p class="text-justify">
						Se tiver alguma dúvida, nossa equipe de atendimento está à disposição para auxiliá-lo.
					</p>
				</BCardText>
				<div class="d-flex justify-content-center">
					<input placeholder="Enviar Arquivo 5" type="file" :state="Boolean(alvaraRetorno)"
						@change="handleFileUpload" class="input-file" id="inputFile" />
					<label class="for-file text-justify" for="inputFile">
						
						<feather-icon
							icon="UploadIcon"
							size="19"
							color="white"
							class="mr-50"
						/>
						{{ nomeArquivo != null ? nomeArquivo : "Enviar Arquivo (Documento ou Foto) "}}
					</label>
				</div>
			</BCardBody>
			<BCardFooter class="d-flex justify-content-start justify-content-md-end">
				<BButton v-if="Boolean(this.alvaraRetorno)" variant="primary" class="col-12 col-md-4 mx-auto" @click="nextStep()"
					:disabled="!preencheRequisitos">
					Continuar
				</BButton>
			</BCardFooter>
		</BCard>


		<BCard v-else-if="!processoEmAndamento && !loading && selectedContract">
			<BCardHeader>
				<BCardTitle class="text-center"> Alvará de Construção </BCardTitle>
			</BCardHeader>
			<BCardBody>
				<BCardText>
					<p>
						<b>Instruções para Solicitação de Demarcação de Lote</b>
					</p>
					<p class="text-justify">
						Para realizar a solicitação de demarcação de lote, será necessário anexar o <b>Alvará de
							Construção</b>. Este documento é imprescindível para que possamos dar andamento à sua solicitação.
					</p>
					<p class="text-justify">
						<b>Caso você não possua o Alvará de Construção</b>, solicitamos que se dirija à Prefeitura de sua
						região. Lá, você deverá apresentar os seguintes documentos para obter a regularização:
					</p>
					<ul class="pl-1 pl-md-2">
						<li>
							<b>Termo de Anuência</b>: Documento que autoriza a execução da obra no local.
						</li>
						<li>
							<b>Contrato de Compra e Venda</b>: Comprovante de que você é proprietário do imóvel.
						</li>
						<li>
							<b>Projeto do Imóvel</b>: Documento detalhado que descreve a obra que será realizada.
						</li>
					</ul>
					<p class="text-justify">
						Lembre-se de que sem o Alvará de Construção ou a regularização por meio dos documentos acima, não será
						possível concluir a solicitação de demarcação de lote.
					</p>
					<p class="text-justify">
						Se tiver alguma dúvida, nossa equipe de atendimento está à disposição para auxiliá-lo.
					</p>
				</BCardText>
				<div class="d-flex justify-content-center">
					<input placeholder="Enviar Arquivo 5" type="file" :state="Boolean(alvaraRetorno)"
						@change="handleFileUpload" class="input-file" id="inputFile" />
					<label class="for-file text-justify" for="inputFile">
						
						<feather-icon
							icon="UploadIcon"
							size="19"
							color="white"
							class="mr-50"
						/>
						{{ nomeArquivo != null ? nomeArquivo : "Enviar Arquivo (Documento ou Foto) "}}
					</label>
				</div>
			</BCardBody>
			<BCardFooter class="d-flex justify-content-start justify-content-md-end">
				<BButton v-if="Boolean(this.alvaraRetorno)" variant="primary" class="col-12 col-md-4 mx-auto" @click="nextStep()"
					:disabled="!preencheRequisitos">
					Continuar
				</BButton>
			</BCardFooter>
		</BCard>

		<ProcessoExistente v-else-if="processoEmAndamento" />
		<div class="d-flex justify-content-center" v-else-if="loading">
			<BSpinner />
		</div>
	</div>
</template>
<style scoped>
.input-file {
	display: none;
}

.for-file {
	cursor: pointer;
	background-color: #e67e22;
	padding: 1rem;
	color: #fff;
	border-radius: 6px;
}


</style>
<script>
import SelectContractLabel from '@/views/components/selectContractLabel.vue'
import ProcessoExistente from '../components/ProcessoExistente.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
	BCard,
	BCardBody,
	BCardFooter,
	BCardHeader,
	BButton,
	BCardText,
	BSpinner,
	BCardTitle
} from 'bootstrap-vue'
export default {
	name: "StepThree",
	components: {
		SelectContractLabel,
		BCard,
		BCardBody,
		BCardFooter,
		BButton,
		BCardText,
		BSpinner,
		BCardHeader,
		ProcessoExistente,
		BCardTitle
	},

	data() {
		return {
			solicitacoes: [],
			loading: false,
			alvaraRetorno: null,
			extensao: null,
			nomeArquivo: null,
		}
	},
	methods: {
		nextStep() {
			if (this.preencheRequisitos) {
				if (this.alvaraRetorno) {
					this.$emit('alvaraAnexo', { alvara: this.alvaraRetorno, extensao: this.extensao })
					this.$emit('nextStep')
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Ops!',
							icon: 'XIcon',
							variant: 'danger',
							text: 'Para solicitar é necessário anexar o alvará de construção.',
						},
					})
				}
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Ops!',
						icon: 'XIcon',
						variant: 'danger',
						text:
							'Para demarcar seu lote seu contrato deve preencher todos os requisitos necessários.',
					},
				})
			}
		},

		handleFileUpload() {
			let that = this
			var selectedFile = document.getElementById("inputFile").files

			if (selectedFile.length > 0) {
				var fileToLoad = selectedFile[0]
				var extensao = fileToLoad.name.split('.').pop()
				var fileReader = new FileReader()
				var base64
				that.extensao = extensao
				that.nomeArquivo = fileToLoad.name

				fileReader.onload = function (fileLoadedEvent) {
					base64 = fileLoadedEvent.target.result.split(',')[1]
					let str = base64
					that.alvaraRetorno = str
				}

				fileReader.readAsDataURL(fileToLoad)
			}
		}
	},

	computed: {
		selectedContract() {
			return store.getters['contracts/getSelectedContract']
		},

		processoEmAndamento() {
			if (this.solicitacoes.length > 0) {
				for (let solicitacao of this.solicitacoes) {
					if (
						solicitacao.id_processo == 'demarcacao_lote' &&
						solicitacao.is_finished != 1 &&
						solicitacao.is_canceled != 1
					) {
						return true
					}
				}
			}
			return false
		},

		isAdimplente() {
			if (this.selectedContract.inadimplente === 'Não') {
				return true
			} else {
				return false
			}
		},
		isIptuAdimplente() {
			if (this.selectedContract.inadimplente_iptu === 'Não') {
				return true
			} else {
				return false
			}
		},
		percentualPago() {
			if (this.selectedContract.percentual_pago >= 9.9) {
				return true
			} else {
				return false
			}
		},

		liberadoConstrucao() {
			if (this.selectedContract.liberado_construcao === 'Sim') {
				return true
			} else {
				return false
			}
		},

		quantosDiasJaQuitado() {
			if (this.selectedContract.data_quitacao) {
				let dataQuitacao = new Date(
					this.selectedContract.data_quitacao.split('-')[0],
					this.selectedContract.data_quitacao.split('-')[1] - 1,
					this.selectedContract.data_quitacao.split('-')[2]
				)

				let dataHoje = new Date()

				let diferenca = dataHoje.getTime() - dataQuitacao.getTime()

				let dias = Math.floor(diferenca / (1000 * 60 * 60 * 24))

				return dias
			} else {
				return 0
			}
		},

		maisDe60DiasNaoEscriturado() {
			if (!this.selectedContract.escriturado && this.quantosDiasJaQuitado > 60) {
				return true
			}
			return false
		},

		preencheRequisitos() {
			if (
				this.isAdimplente &&
				this.isIptuAdimplente &&
				this.percentualPago &&
				this.liberadoConstrucao &&
				!this.maisDe60DiasNaoEscriturado &&
				Boolean(this.alvaraRetorno)
			) {
				return true
			} else {
				return false
			}
		},

	},
}
</script>
